import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { path, prop } from 'ramda';
import { ErrorMessage } from '../components/feedback/errorMessage';
import { useDictionary } from '../context/dictionary';
import { SEO } from '../components/core/seo';

export const query = graphql`
  query Error404Query {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
  }
`;

const Error404 = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const site = prop('site', data);

  if (!settings || !site) return null;

  const heading404 = useDictionary("heading404");
  const text404 = useDictionary("text404");

  return (
    <>
      <SEO
        favicon={site.faviconMetaTags}
      />
      <ErrorMessage heading={heading404} text={text404} />
    </>
  );
};

export default Error404;